import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Sidebar, Menu, Sticky, Ref } from 'semantic-ui-react'
import styled from 'styled-components'
import 'semantic-ui-css/semantic.min.css'
import { Toaster } from 'react-hot-toast'

// Components
import { Header, Footer, Menu as SidebarMenu } from '../components'
// Styles
import GlobalStyle from '../styles/GlobalStyle'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 100vw;
  background: #fff;

  &&& {
    .ui.left.overlay.sidebar {
      max-height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      overflow-y: scroll;
      overscroll-behavior: contain;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const ContentWrapper = styled.div`
  min-height: calc(100vh - 240px);
  @media ${({ theme }) => theme.device.sm} {
    padding: ${({ isFullWidth }) => (isFullWidth ? '0' : '0 15%')};
    min-height: calc(100vh - 240px);
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.xs} {
    flex: 1 0 calc(50vw - 10px);
  }
  @media ${({ theme }) => theme.device.sm} {
    flex: 1 0 calc(25vw - 10px);
  }
`

const DefaultLayout = ({ children, isFullWidth }) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const stickRef = createRef()

  const handleShowSidebar = () => setShowSidebar(!showSidebar)
  return (
    <Wrapper>
      <GlobalStyle />
      <Toaster />
      <Ref innerRef={stickRef}>
        <Sidebar.Pushable style={{ transform: 'none' }}>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={() => setShowSidebar(false)}
            vertical
            visible={showSidebar}
          >
            <SidebarMenu toggle={handleShowSidebar} />
          </Sidebar>

          <Sidebar.Pusher dimmed={showSidebar}>
            <Sticky context={stickRef}>
              <Header showSidebar={handleShowSidebar} />
            </Sticky>
            <ContentWrapper isFullWidth={isFullWidth}>
              {children}
            </ContentWrapper>
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Ref>
    </Wrapper>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isFullWidth: PropTypes.bool,
}

export default DefaultLayout
