import styled from 'styled-components'
import environment from '../../utils/environment'

export const ShoppingWith = styled.div`
  background-color: ${({ theme }) =>
    environment.IS_VIP_SITE
      ? theme.colors.vipBlue
      : theme.colors.turquoiseGreen};
  color: ${({ theme }) =>
    environment.IS_VIP_SITE ? theme.colors.secondaryBlack : theme.colors.black};
  display: flex;
  justify-content: center;
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px 0;
  width: 100%;
`
