import { getUserLanguage, saveUserLanguage } from './languages'
import { getUserCountry, saveUserCountry } from './countries'
import { groupBy } from 'lodash'
import { Market, StoreView } from '../../types/i18n'
import { saveUserLocale } from './locale'
import environment from '../environment'

const DEFAULT_STORE_CODE = 'en_US'

export const STORE_KEY = 'USER_STORE'

export const storeViews: StoreView[] = [
  {
    store_code: 'en_US', // Store View Code
    store_name: 'US English', // Store - Name
    store_group_code: 'us_store', // Store - Code
  },
  {
    store_code: 'es_US',
    store_name: 'US Spanish',
    store_group_code: 'us_store',
  },
  {
    store_code: 'Event',
    store_name: 'Event',
    store_group_code: 'Event',
  },
  {
    store_code: 'ot_view',
    store_name: 'Owners Tour',
    store_group_code: 'ot',
  },
  {
    store_code: 'vipStore',
    store_name: 'VIP',
    store_group_code: 'vip',
  },
  {
    store_code: 'en_AU',
    store_name: 'Australia',
    store_group_code: 'au_store',
  },
  {
    store_code: 'as_store',
    store_name: 'Autoship',
    store_group_code: 'AS',
  },
  {
    store_code: 'en_NZ',
    store_name: 'New Zealand',
    store_group_code: 'nz_store',
  },
  // {
  //   store_code: 'es_US',
  //   store_name: 'US Spanish',
  //   store_group_code: 'us_store',
  // },
  {
    store_code: 'fr_CA',
    store_name: 'Canada French',
    store_group_code: 'ca_store',
  },
  {
    store_code: 'en_CA', // Store View Code
    store_name: 'Canada English', // Store - Name
    store_group_code: 'ca_store', // Store - Code
  },
  {
    store_code: 'testOpsView',
    store_name: 'Test Ops',
    store_group_code: 'testOps',
  },
]

/**
 * Gets the users store view for the site
 * First, gets the users store from settings
 * If no store saved, gets the users country/language and finds a matching store
 * if there is no matching store, uses the US english store view
 * If the site is the event site, return the event site store
 * @param storage localstorage
 * @param locale locale string provided from 'navigator.language' (i.e. 'en-US' or 'en')
 */
export const getStoreView = (storage: Storage, locale: string) => {
  const userLang = getUserLanguage(storage, locale)
  const userCountry = getUserCountry(storage, locale)

  let storeCode =
    _getStoreViewCodeFromStorage(storage) ?? `${userLang}_${userCountry}`

  let storeView =
    _getStoreViewFromCode(storeCode) ??
    _getStoreViewFromCode(DEFAULT_STORE_CODE)

  // SET CORRECT STORE VIEW FOR PRODUCTION SITES
  if (environment.ENV == 'opsProduction') {
    if (environment.IS_OPS_TEST) {
      storeView = _getStoreViewFromCode('testOpsView')
    }
  }
  if (environment.ENV === 'production') {
    if (environment.IS_EVENT_SITE) storeView = _getStoreViewFromCode('Event')
    if (environment.IS_VIP_SITE) storeView = _getStoreViewFromCode('vipStore')
    if (environment.IS_OWNERS_TOUR) storeView = _getStoreViewFromCode('ot_view')
  }
  if (environment.ENV === 'vipStaging' && environment.IS_VIP_SITE) {
    storeView = _getStoreViewFromCode('vipStore')
  }
  if (environment.ENV === 'eventStaging' && environment.IS_EVENT_SITE) {
    storeView = _getStoreViewFromCode('Event')
  }
  return storeView
}

/**
 * On save of store view, saves the language, country, and store view
 * @param storage localstorage
 * @param storeViewCode store code in magento
 */
export const saveStoreView = (storage: Storage, storeViewCode: string) => {
  _saveStoreViewCode(storage, storeViewCode)
  const locale = storeViewCode.replace('_', '-')
  saveUserLocale(storage, locale)
  saveUserCountry(storage, locale)
  saveUserLanguage(storage, locale)
}

export const _groupStoresIntoMarkets = () => {
  const markets: Market[] = []
  const groupedStores = groupBy(storeViews, 'store_group_code')
  for (const [storeId, storeViews] of Object.entries(groupedStores)) {
    markets.push({
      key: storeId,
      value: storeId,
      text: storeId,
      storeViews,
    })
  }
  return markets
}

export const marketList = _groupStoresIntoMarkets()

export const getMarketByCode = (marketCode: string) => {
  return marketList.find(({ key }) => key === marketCode)
}

export const _getStoreViewFromCode = (storeViewCode: string) => {
  return storeViews.find(store => store.store_code === storeViewCode)
}

export const _getStoreViewCodeFromStorage = (storage: Storage) => {
  return storage.getItem(STORE_KEY)
}

export const _saveStoreViewCode = (storage: Storage, storeViewCode: string) => {
  return storage.setItem(STORE_KEY, storeViewCode)
}

//for reference: possible locales given by browser
// const locales = ["af", "sq", "ar-SA", "ar-IQ", "ar-EG", "ar-LY", "ar-DZ", "ar-MA", "ar-TN", "ar-OM",
// "ar-YE", "ar-SY", "ar-JO", "ar-LB", "ar-KW", "ar-AE", "ar-BH", "ar-QA", "eu", "bg",
// "be", "ca", "zh-TW", "zh-CN", "zh-HK", "zh-SG", "hr", "cs", "da", "nl", "nl-BE", "en",
// "en-US", "en-EG", "en-AU", "en-GB", "en-CA", "en-NZ", "en-IE", "en-ZA", "en-JM",
// "en-BZ", "en-TT", "et", "fo", "fa", "fi", "fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU",
// "gd", "gd-IE", "de", "de-CH", "de-AT", "de-LU", "de-LI", "el", "he", "hi", "hu",
// "is", "id", "it", "it-CH", "ja", "ko", "lv", "lt", "mk", "mt", "no", "pl",
// "pt-BR", "pt", "rm", "ro", "ro-MO", "ru", "ru-MI", "sz", "sr", "sk", "sl", "sb",
// "es", "es-AR", "es-GT", "es-CR", "es-PA", "es-DO", "es-MX", "es-VE", "es-CO",
// "es-PE", "es-EC", "es-CL", "es-UY", "es-PY", "es-BO", "es-SV", "es-HN", "es-NI",
// "es-PR", "sx", "sv", "sv-FI", "th", "ts", "tn", "tr", "uk", "ur", "ve", "vi", "xh",
// "ji", "zu"];
