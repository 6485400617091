import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Rating, Image, Icon } from 'semantic-ui-react'
// Component
import AddToModal from './AddToModal'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useCartContext } from '../../context/CartContext'
// Hooks
import useProductPriceSum from '../../hooks/useProductPriceSum'
// Styles
import {
  AddButton,
  CardDesc,
  CardTitle,
  CardContent,
  RatingContainer,
  RatingContent,
} from './ProductCard.styles'
import { Container } from '../../components'

const ProductRating = ({ stars = 0, summary, nickname }) => (
  <RatingContainer>
    <Icon name="user circle outline" size="big" />
    <RatingContent>
      <div>
        <Rating disabled rating={stars} maxRating={5} size="large" />
      </div>
      <div>{summary}</div>
      <div>{`- ${nickname}`}</div>
    </RatingContent>
  </RatingContainer>
)

export const QHomeProductCard = ({ product, backgroundColor, direction }) => {
  const {
    prismicData: {
      prismicProductsPage: {
        add,
        default_rating_summary,
        default_rating_nick_name,
        out_of_stock,
      },
    },
  } = usePrismic()

  const {
    autoship,
    image,
    items,
    media_gallery,
    name,
    reviews,
    short_description,
    sku,
    stock_status,
    trending = false,
    url_key,
    hide_for_purchase,
    background_color,
  } = product

  // Conditionally set vars if product has existing reviews
  let nickname, summary, average_rating
  if (reviews?.items[0]) {
    ;({ nickname, summary, average_rating } = reviews.items[0])
  }

  const { userType, isEventSite, isVipSite } = useAuthContext()
  const {
    buildCart,
    cartId,
    isAmbOfferInCart,
    isPcOfferInCart,
    manageCart,
    showCartPopup,
    doesExceedMaxOrder,
    setIsMaxOrderModalOpen,
  } = useCartContext()

  const [isLoading, setIsLoading] = useState(false)
  const [openAddToModal, setOpenAddToModal] = useState(false)

  const { productPriceSum } = useProductPriceSum(product)

  const isCartMaxedOut = doesExceedMaxOrder(productPriceSum, 'cart')
  const isAutoShipMaxedOut = doesExceedMaxOrder(productPriceSum, 'autoship')
  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL') &&
    autoship

  const handleAddToCart = async () => {
    if (
      (isCartMaxedOut && isAutoShipMaxedOut) ||
      (!showAutoShip && isCartMaxedOut)
    ) {
      return setIsMaxOrderModalOpen(true)
    }
    if (showAutoShip) {
      setOpenAddToModal(true)
      return
    }
    if (product.configurable_options) {
      navigate(`/products/${url_key}`)
      return
    }
    setIsLoading(true)
    try {
      items
        ? await manageCart.addBundleProductToCart(product, 1, cartId, buildCart)
        : await manageCart.addItemToCart(product, 1, cartId, buildCart)

      showCartPopup(true)
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)
  const productImage = sortedImages[0]?.url ?? image.url
  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  return (
    <Container
      align="flex-start"
      maxWidth="700px"
      style={{ backgroundColor: background_color }}
      direction={direction}
    >
      <Container direction="column">
        {hide_for_purchase === null ? (
          <Image
            data-qa={`qHomeProductImage-${sku}`}
            as={Link}
            to={`/products/${url_key}`}
            src={productImage}
            style={{ marginBottom: '1rem' }}
          />
        ) : (
          <Image
            data-qa={`qHomeProductImage-${sku}`}
            src={productImage}
            style={{ marginBottom: '1rem' }}
          />
        )}
        {hide_for_purchase === null ? (
          <AddButton
            loading={isLoading}
            disabled={isLoading || isOutOfStock}
            content={isOutOfStock ? out_of_stock[0].text : add[0].text}
            onClick={handleAddToCart}
            data-qa={`qHomeProductAdd-${sku}`}
          />
        ) : null}
      </Container>
      <Container padding="1rem">
        <CardContent>
          <CardTitle data-qa={`qHomeProductName-${sku}`}>{name}</CardTitle>
          <CardDesc data-qa={`qHomeProductDesc-${sku}`}>
            {short_description?.html && (
              <div
                dangerouslySetInnerHTML={{ __html: short_description.html }}
              />
            )}
          </CardDesc>
        </CardContent>
        {trending ? (
          <CardContent>
            <ProductRating
              stars={average_rating / 20}
              summary={summary || default_rating_summary[0].text}
              nickname={nickname || default_rating_nick_name[0].text}
            />
          </CardContent>
        ) : null}
      </Container>
      {openAddToModal && (
        <AddToModal
          open={openAddToModal}
          setShowModal={setOpenAddToModal}
          product={product}
        />
      )}
    </Container>
  )
}
